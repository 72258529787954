<div class="main-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="main-banner-content" *ngFor="let Content of mainBannerContent;">
                    <div class="c-v2-intro-title c-v2-intro-title-fade-words animation-element fade-in-up in-view"><h1><span class="animate-charcter">Transforming </span>{{Content.title}}</h1><div id="c-v2-intro-title-hidden" class="c-v2-intro-title-hidden">     
                    </div>
                </div>
                    <p class="wow animate__animated animate__fadeInLeft" data-wow-delay="100ms" data-wow-duration="1000ms">{{Content.paragraphText}}</p>
                    <div class="btn-box">
                        <a routerLink="/{{Content.defaultBtnLink}}" class="default-btn wow animate__animated animate__fadeInRight" data-wow-delay="200ms" data-wow-duration="1000ms"><i class="{{Content.defaultBtnIcon}}"></i>{{Content.defaultBtnText}}<span></span></a>
                        <a href="{{Content.videoBtnLink}}" class="video-btn wow animate__animated animate__fadeInLeft popup-youtube"><i class="{{Content.videoBtnIcon}}"></i> {{Content.videoBtnText}}</a>
                    
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-12"  style="margin-left: -3%;">
                    <img src="assets\img\OptebizImage\home flow Latest.svg"class="wow animate__animated animate__fadeInDown" data-wow-delay="100ms" data-wow-duration="1000ms" alt="image" data-speed="0.06" data-revert="true" style="opacity: 0.8;" loading="lazy" class="media">
            </div>
        </div>
    </div>
</div>