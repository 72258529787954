<section class="services-area ptb-70  bg-f1f8fb">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subtitle}}</span>
            <h2  class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms" style="color: #30307f;"><span style="background: -webkit-linear-gradient(rgb(4, 79, 103),rgb(230, 95, 50));-webkit-background-clip: text;-webkit-text-fill-color: transparent;"> Transforming </span>{{sectionTitleContent.title}}</h2>
            <p style="font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #4e5f6d;
            max-width: 935px;
            margin: 7px auto 32px;">{{sectionTitleContent.paragraphText}}</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let ServicesBoxContent of singleServicesBoxItem;">
                <div class="single-services-box-item">
                    <div class="icon">
                        <img [src]="ServicesBoxContent.icon" alt="image">
                    </div>
                    <h3 style="color: #ff6e42;
                    background:  linear-gradient(90deg,rgb(4, 79, 103),rgb(230, 95, 50));;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    -webkit-box-decoration-break: clone;
                    font-weight: 700;"><a>{{ServicesBoxContent.title}}</a></h3>
                    <p style=" font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    max-width: 350px;
                    padding-bottom: 30px;">{{ServicesBoxContent.paragraphText}}</p>
                    <a routerLink="/{{ServicesBoxContent.link}}" class="learn-more-btn"><i ></i>{{ServicesBoxContent.linkText}}&nbsp;&nbsp;<i class="{{ServicesBoxContent.linkIcon}}"></i></a>
                    <!-- <a routerLink="/{{ServicesBoxContent.link}}" class="read-more-btn">{{ServicesBoxContent.linkText}} <i class="{{ServicesBoxContent.linkIcon}}"></i></a> -->

                    <div class="shape"><img src="assets/img/services/shape4.png" alt="image"></div>
                </div>
            </div>
        </div>

        
        
    </div>

    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</section>