<div id="main" class="site-main- clearfix">
    <div class="hero-section yb-section">
       <div class="headline text-center">
          <div class="container">
             <div class="left-side">
                <h1><span class="gradient-word">Bringing the Power</span> of Data to Your Hands in Inductive Automation</h1>
                <div class="subheading"> Unsure of exactly what you need? Already have a data solution but not getting results? OpteBiz offers a dynamic, integrated solution built around you. Our team will consult with you to determine the technology and platforms you need to fully integrate automation in your industry – and bring the power of data into your own hands.
                </div>
             </div>
             <div class="right-side" style="top: -10px;" > <img src="assets\img\OptebizImage\inductive main pagelatest1.svg" alt="Inductive Automation" title="Inductive Automation"   class="loaded come-in"class="wow animate__animated animate__fadeInDown" style="height: 458px;top: 55px;"></div>
          </div>
          <div class="container">
             <div class="cards">
                <div class="card">
                   <h3>Consultation</h3>
                   <div class="short-detail" style="color: #4e5f6d;"> We analyze your current operations and systems involved and any previous data-related work.</div>
                </div>
                <div class="card">
                   <h3>Exploration</h3>
                   <div class="short-detail" style="color: #4e5f6d;"> Find the areas that need help the most – filling gaps present from previous solutions, or assessing a solution from scratch.</div>
                </div>
                <div class="card">
                    <h3>Integration</h3>
                    <div class="short-detail" style="color: #4e5f6d;">Work with your team to implement our solutions, and guide you through the system – ensuring the product is built around you, for you.</div>
                 </div>
             </div>
          </div>
       </div>
    </div>
    <div class="now-available-section yb-section">
       <section class="section side-content">
        <div class="c-row">
           <h2><span>OpteBiz Creates a Direct Impact on Your Bottom Line</span></h2>
           <p>This means our solution has a clear and immediate effect on your financial performance. Whether it's increasing revenue, reducing costs, or both, we're all about making a positive impact on your bottom line.</p>
           <div class="side-content-wrapper come-out come-in">
              <div class="side-box">
                 <div class="side-txt-row">
                    <img src="assets\img\OptebizImage\save time.png" data-src="assets\img\OptebizImage\facto IQ2.png" alt="Save Time" title="Save Time" width="65" height="65" class="lazy-loaded" style="top: 35px;"> 
                    <h3><span>Save</span> Time</h3>
                    <p>OpteBiz helps you decrease production lead time. Save time in data collection, analysis and retrieval with automated data acquisition (DAQ) devices. OpteBiz software solutions offer intelligence to optimize delivery operation, speeding processes.</p>
                 </div>
                 <div class="side-txt-row">
                    <img src="assets\img\OptebizImage\reduce cost.png" data-src="assets\img\OptebizImage\reduce cost.png" alt="Reduce Costs" title="Reduce Costs" width="65" height="65" class="lazy-loaded" style="top: 35px;"> 
                    <h3><span>Reduce </span> Costs</h3>
                    <p>The bottom-line matters. OpteBiz provides low-cost solutions that optimize profitability. When you reduce operational costs, net present value (NPV) increases. With our MES solutions and other Facto-Tools, reduce operational, infrastructure, material, and labor costs; in addition, our solutions protect your profits through deliberate and impactful data-driven decisions.</p>
                 </div>
                 <div class="side-txt-row">
                    <img src="assets\img\OptebizImage\downtime reduce.png" data-src="assets\img\OptebizImage\downtime reduce.png" alt="Minimize Downtime" title="Minimize Downtime" width="65" height="65" class="lazy-loaded"style="top: 35px;"> 
                    <h3><span>Minimize Downtime</span> Increase Uptime</h3>
                    <p>With OpteBiz Facto Solutions, you can monitor data to minimize downtime of machinery. Monitoring the machinery operations can keep it in better condition, enhancing its longevity. In addition, the cycle time of the machine task can be optimized with advanced analytics that will pinpoint variables with the most impact on productivity.</p>
                 </div>
                 <div class="side-txt-row">
                    <img src="assets\img\OptebizImage\product improve.png" data-src="assets\img\OptebizImage\product improve.png" alt="Improve" title="Improve" width="65" height="65" class="lazy-loaded"style="top: 35px;"> 
                    <h3><span>Improve</span> Product Quality</h3>
                    <p>Real-time data provides insight into operational processes. Facto Solutions provides operational tracking with alert capabilities. When processes meet an error or machinery fails, you are notified to quickly resolve the situation.</p>
                 </div>
                 <!-- <div class="side-button"> <a class="gradiant-btn" href="/yugabytedb-and-vmware-tanzu-on-demand-webinar-may-2022/" title="Watch the Webinar to Learn More"> Watch the Webinar to Learn More </a></div> -->
              </div>
              <div class="side-image" style="background-image:url(assets/img/OptebizImage/diagram-gradient.svg);"> <img src="assets\img\OptebizImage\inductiveAutomationLatest.svg" data-src="assets\img\OptebizImage\inductiveAutomationLatest.svg" alt="inductiveAutomationFlow" title="inductiveAutomationFlow" width="681" height="396" class="lazy-loaded" class="wow animate__animated animate__fadeInRight"></div>
           </div>
        </div>
     </section>
    </div>
    <div class="gcp-section yb-section">
       <div class="container">
          <h2 class="section-heading" style="font-weight: 700;
          font-size: 38px;
          line-height: 48px;
          color: #000041;
          margin-bottom: 16px;"> Be <span class="gradient-word">  Future ready </span> with OpteBiz</h2>
          <div class="short-detail" style="font-weight: 400;
          font-size: 18px;
          line-height: 30px;
          color: #4e5f6d;
          max-width: 935px;
          margin: 0 auto 32px;"> OpteBiz provides a development environment equipped with everything you need to create a wide range of industrial applications, including SCADA, IIoT, MES, and more—all on one platform.</div>
          <div class="gcp-boxes">
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_webBasedDeployment.png" data-src="assets\img\OptebizImage\introIcon_webBasedDeployment.png" alt="Web-Based Deployment" title="Web-Based Deployment" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Web-Based Deployment</h3>
                   <div class="short-detail">Web-Based Deployment in Ignition by Inductive Automation allows users to effortlessly monitor and control industrial projects through standard web browsers, ensuring convenience and efficiency across various devices for a user-friendly experience.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" data-src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" alt="Rapid Development" title="Rapid Development" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Rapid Development</h3>
                   <div class="short-detail">In Inductive Automation's Ignition platform enables swift creation and deployment of industrial automation solutions, streamlining the process for designing and implementing projects like HMI, SCADA, and MES.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_ITStandards.png" data-src="assets\img\OptebizImage\introIcon_ITStandards.png" alt="Built on Trusted IT standards" title="Built on Trusted IT standards" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Built on Trusted IT standards</h3>
                   <div class="short-detail"> FactoTools is built upon information technology standards that are dependable and widely accepted, guaranteeing compatibility, security, and reliability.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-data-collection-analytics.png" data-src="assets\img\OptebizImage\introicon-data-collection-analytics.png" alt="Data Collection &amp; Analytics" title="Data Collection &amp; Analytics" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Data Collection &amp; Analytics</h3>
                   <div class="short-detail"> Our applications efficiently gather data from various sources, employing analytical tools to derive meaningful insights, thereby supporting informed decision-making, optimizing performance, and uncovering patterns or trends within the data.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-automation.png" data-src="assets\img\OptebizImage\introicon-automation.png" alt="Automation" title="Automation" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Automation</h3>
                   <div class="short-detail"> Leveraging cutting-edge automation technology to execute tasks autonomously, improving efficiency, and minimizing manual intervention in repetitive or labor-intensive processes across diverse industries.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" data-src="assets\img\OptebizImage\introIcon_rapidDevelopment.png" alt="Reduce Development Time" title="Reduce Development Time" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Reduce Development Time</h3>
                   <div class="short-detail"> Reducing development time in Inductive Automation with Ignition involves streamlining processes to swiftly design and deploy industrial automation projects such as HMI, SCADA, and MES. The goal is to accelerate project delivery, improve efficiency, and promptly address evolving automation needs.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\IntroIcon-Sepasoft-BetterAnalyze.svg" data-src="assets\img\OptebizImage\IntroIcon-Sepasoft-BetterAnalyze.svg" alt="Analyze &amp; Increase Performance" title="Analyze &amp; Increase Performance" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Analyze &amp; Increase Performance</h3>
                   <div class="short-detail"> Being an AWS SaaS Partner, OpteBiz provides cloud-based analytics solutions that afford you a comprehensive overview of your processes, coupled with actionable insights to enhance productivity and mitigate wastage.</div>
                </div>
             </div>
             <div class="box-inner">
                <div class="image-area"> <img src="assets\img\OptebizImage\introicon-scalable-architecture.png" data-src="assets\img\OptebizImage\introicon-scalable-architecture.png" alt="Scalable Architecture" title="Scalable Architecture" width="32" height="32" class="loaded come-in"></div>
                <div class="content-inner">
                   <h3>Scalable Architecture</h3>
                   <div class="short-detail">This ensures efficient scaling without the need for a complete overhaul, enabling seamless addition of devices, data points, or features as needed for optimal performance as the system evolves.</div>
                </div>
             </div>
             
             
          </div>
       </div>
    </div>
    <div class="pdf-download-section yb-section dark-bg">
       
       <div class="c-v2-optebiz-section-triangle"></div>
       <div class="container">
          <div class="content-area">
             <div class="left-area">
                <h2 class="section-heading"> Certified by Inductive Automation<span><img src="assets\img\OptebizImage\igni_partner_4.png" data-src="assets\img\OptebizImage\igni_partner_4.png" alt="Certified Ignition" title="Certified Ignition" width="120" height="100" style="margin-left: -7%;"></span> <br>
                  <span class="gradient-word"> Implementing Standardized & Custom Solutions.</span></h2>
                <div class="short-detail"> OpteBiz is a Certified Integrator of Ignition. Our status ensures you are working with experts who can understand the fundamentals of your business, and effectively apply Ignition-based solutions to fit what you need.A 
                    We have worked in manufacturing, energy, and utility industries, giving us the experience to build custom solutions for your operation, big or small. We maintain our certification for the latest software of Ignition..</div>
             </div>
             <div class="c-columns c-v2-optebiz-section-img c-center-text">
               <img src="assets\img\OptebizImage\mobile-responsive.png" data-src="assets\img\OptebizImage\mobile-responsive.png" alt="" width="550" height="703" class="loaded come-in" >
              <br>
              <br>
               <div  class="gradient-word"> Inductive Automation by Ignition</div>
               <div class="gradient-word"> <span>A Single Tool</span> for All Digital Transformation Solutions</div>
            </div>
          </div>
       </div>
       <div class="connector"></div>
    </div>
    
    <div class="explore-further come-out yb-section dark-bg loaded come-in">
       
       <div class="c-v2-optebiz-section-triangle"></div>
       <div class="container">
          <h2 class="section-heading"> <span class="gradient-word">OpteBiz </span>Methodology in Ignition</h2>
          <div class="detail-sec">
             <p class="short-detail"><span style="font-weight: 400;">Optebiz is a Certified Integrator of Ignition. Our status ensures you are working with experts who can understand the fundamentals of your business, and effectively apply Ignition-based solutions to fit what you needs. </span></p>
             <p class="short-detail"><span style="font-weight: 400;">We have worked in manufacturing, energy, and utility industries, giving us the experience to build custom solutions for your operation, big or small. We maintain our certification for the latest software of Ignition.</span></p>
          </div>
          <br>
          <div class="c-columns c-v2-optebiz-section-img c-center-text">
            <img src="assets\img\OptebizImage\IA-CSIA-Show-diagram-WEB-1.png" data-src="assets\img\OptebizImage\IA-CSIA-Show-diagram-WEB-1.png" alt="" width="963" height="703" class="loaded come-in">
            
         </div>
       </div>
    </div>
 </div>


