<div class="platforms-area pb-70">
<div  class="container">
    <div class="section-title">
        <span class="sub-titleLatest"><img src="assets/img/star-icon.png" alt="image"> Our Solutions</span>
        <!-- <h2  class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms" style="color: #30307f;;">{{sectionTitleContent.title}}<span style="background: -webkit-linear-gradient(rgb(4, 79, 103),rgb(230, 95, 50));-webkit-background-clip: text;-webkit-text-fill-color: transparent;"> Optebiz Value</span></h2> -->
        <p style=" font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #4e5f6d;
        max-width: 935px;
        margin: 7px auto 32px;">Our solutions built on latest technologies are capable to manage and optimize manufacturing operations within your organization</p>
    </div>
    <div  class="row justify-content-center">
       <div  class="col-lg-4 col-sm-6">
          <div  class="platforms-item">
             <div  class="image"><img  src="assets/img/OptebizImage/Execution1.jpg" alt="image" height="40" width="110"></div>
             
             <h3 class="gradient-word" >Execution</h3>
             <div  class="arrow-image"><img  src="assets\img\OptebizImage\arrow-1.png" alt="image"></div>
          </div>
       </div>
       <div  class="col-lg-4 col-sm-6">
          <div  class="platforms-item">
             <div  class="image"><img  src="assets/img/OptebizImage/Monitor2.jpg" alt="image" height="40" width="100"></div>
             <h3 class="gradient-word">Monitoring</h3>
             <div  class="arrow-image"><img  src="assets\img\OptebizImage\arrow-2.png" alt="image"></div>
          </div>
       </div>
       <div  class="col-lg-4 col-sm-6">
          <div  class="platforms-item">
             <div  class="image"><img  src="assets/img/OptebizImage/AnalticsFront.png" alt="image" ></div>
             <h3 class="gradient-word">Analytics</h3>
          </div>
       </div>
    </div>
 </div>
</div>
<div class="use-cases-section come-out come-in loaded">
   
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2  class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms" style="color: #30307f;;">{{sectionTitleContent.title}}<span style="background: -webkit-linear-gradient(rgb(4, 79, 103),rgb(230, 95, 50));-webkit-background-clip: text;-webkit-text-fill-color: transparent;"> Operational Excellence</span></h2>
            <!-- <p style=" font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #4e5f6d;
            max-width: 935px;
            margin: 7px auto 32px;">{{sectionTitleContent.paragraphText}}</p> -->
        </div>
        <!-- <h2>Delivering value through <span class="gradient-word"> Operational Excellence</span></h2> -->
        <div class="use-cases">
            <div class="single-case">
                <div class="content-section">
                    <div class="heading"><span class="gradient-word">Parterning With </span><span><img src="assets\img\OptebizImage\Inductive-Automation-Ignition-Designer-Logo.png" alt="Ignition" title="Ignition" width="150" height="150" style="margin-top: -26px;"></span>
                    </div>
                    <div class="detail">Our partnership with Inductive Automation ensures that our Ignition-based SCADA and HMI solutions are at the forefront of cutting-edge technology, providing unmatched efficiency for your operations.</div>
                    <div class="button-section"> 
                        <a routerLink="/inductive-details" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</a>
                    </div>
                </div>
                <div class="image-section" > <img src="assets\img\OptebizImage\Downloads.png" data-src="assets\img\OptebizImage\Downloads.png" alt="Inductive Automation" title="Inductive Automation" style="filter: drop-shadow(0 0 0.75rem #044F67);"><span><img src="assets\img\OptebizImage\inductive-automation-llc-logo2.png" data-src="assets\img\OptebizImage\inductive-automation-llc-logo2.png" alt="Inductive Automation" title="Inductive Automation" style="height: 100px;margin-top: -24px;margin-left: 163px;"></span>
                </div>
            </div>
            <div class="single-case"><div class="content-section"><div class="heading"><span class="gradient-word">Saas</span><span class="gradient-word"> Solution On</span><span><img src="assets\img\OptebizImage\Amazon_Web_Services.png" alt="AWS" title="AWS" width="100" height="150" style="margin-top: 10px;"></span>
            </div>
            <div class="detail">Facto Tools is now available as Saas over AWS also which unlocks unparalleled scalability and reliability for your operations with flexible plans to fit right into your present and future roadmap.</div>
            <div class="button-section"> 
                <a routerLink="/aws" class="learn-more-btn"><i class="flaticon-right"></i>Learn More</a>
            </div>
        </div>
        <div class="image-section"> <img src="assets\img\OptebizImage\cloud-partner-aws-1024x811_Update1.png" data-src="assets\img\OptebizImage\cloud-partner-aws-1024x811_Update1.png" alt="AWS" title="AWS" width="1500" height="200" style="filter: drop-shadow(0 0 0.75rem #E65F32);">
        </div>
    </div>
</div> 
</div>
</div>