import { Component } from '@angular/core';


@Component({
  selector: 'app-facto-tool-kit',
  templateUrl: './facto-tool-kit.component.html',
  styleUrls: ['./facto-tool-kit.component.scss']
})
export class FactoToolKitComponent {

  Operational:number = 0;
  Clients:number =0;
  Savings :number=0;

  constructor() { }

  ngOnInit(): void {
  }

// 
  Operationalstop:any = setInterval(()=>{
    this.Operational++;
    //we need to stop this at  particular point; will use if condition
    if(this.Operational ==24)
    {
      //clearinterval will stop tha function
      clearInterval(this.Operationalstop);
    }

  },50)
  // 
  Clientsstop:any = setInterval(()=>{
    this.Clients++;
    //we need to stop this at  particular point; will use if condition
    if(this.Clients ==35)
    {
      //clearinterval will stop tha function
      clearInterval(this.Clientsstop);
    }

  },50)
// 
Savingsstop:any = setInterval(()=>{
  this.Savings++;
  //we need to stop this at  particular point; will use if condition
  if( this.Savings==70)
  {
    //clearinterval will stop tha function
    clearInterval(this.Savingsstop);
  }

},20)
// 
sectionTitle: sectionTitleContent[] = [
  {
      subtitle:"What Our Facto-Tools Accomplish",
      title: 'Business with Best in Class Solutions',
      paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach.'
  }
]
singleServicesBoxItem: ServicesBoxContent[] = [
  {
      icon: 'assets/img/OptebizImage/Asset 6@36x.png',
      title: 'FactoIQ',
      paragraphText: ' Collect Realtime timeseries data in a timeseries database and analyze the shop floor process, maintenance, manufacturing and operations data using statistical and AI methodologies',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoIQ'
  },
  {
      icon: 'assets/img/OptebizImage/FactoMES.svg',
      title: 'FactoMes',
      paragraphText: 'Delivering operational excellence with our ISA-95 compliant FactoMes Solution a cutting-edge platform designed to elevate manufacturing processes and ensure seamless integration',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoMES'
  },
  {
      icon: 'assets/img/OptebizImage/Asset 4@36x.png',
      title: 'FactoCloudConnect',
      paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shopfloor and machine control system into various cloud environments and Big Data Platforms',
      linkText: 'Learn More',
      linkIcon: 'flaticon-right',
      link: 'FactoCloud'
  },
  
]


}
class sectionTitleContent {
  subtitle:string;
  title : string;
  paragraphText : string;
  }
  class ServicesBoxContent {
  icon : string;
  title : string;
  paragraphText : string;
  linkText : string;
  linkIcon : string;
  link : string;
  }