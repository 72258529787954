import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-homeone-partner',
    templateUrl: './homeone-partner.component.html',
    styleUrls: ['./homeone-partner.component.scss']
})
export class HomeonePartnerComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

sectionTitle: sectionTitleContent[] = [
    {
        subtitle:"Our Products",
        title: 'Business with Best in Class Solutions',
        paragraphText: 'Our Facto Tools and the expert team behind it is dedicated to implementing systems that seamlessly collect, store, analyze and present your data. No more guesswork – our integrated, dynamic solution is tailored specifically to meet your unique business needs. We understand that every company is different, and so is our approach'
    }
]
singleServicesBoxItem: ServicesBoxContent[] = [
    {
        icon: 'assets/img/OptebizImage/Asset 6@36x.png',
        title: 'FactoIQ',
        paragraphText: ' Collect Realtime timeseries data in a timeseries database and analyze the shop floor process, maintenance, manufacturing and operations data using statistical and AI methodologies',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoIQ'
    },
    {
        icon: 'assets/img/OptebizImage/FactoMES.svg',
        title: 'FactoMes',
        paragraphText: 'Delivering operational excellence with our ISA-95 compliant Facto-Mes Solution – a cutting-edge platform designed to elevate manufacturing processes and ensure seamless integration',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoMES'
    },
    {
        icon: 'assets/img/OptebizImage/Asset 4@36x.png',
        title: 'FactoCloudConnect',
        paragraphText: 'Our Factocloud Connect helps integrate the Real-Time-Data coming from shop floor and machine control system into various cloud environments and Big Data Platforms',
        linkText: 'Learn More',
        linkIcon: 'flaticon-right',
        link: 'FactoCloud'
    },
    
]

}
class sectionTitleContent {
subtitle:string;
title : string;
paragraphText : string;
}
class ServicesBoxContent {
icon : string;
title : string;
paragraphText : string;
linkText : string;
linkIcon : string;
link : string;
}