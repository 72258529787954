
<section class="services-area pt-100">
    <div class="container">
        <div class="section-title" *ngFor="let sectionTitleContent of sectionTitle;">
            <span class="sub-title"><img src="assets/img/star-icon.png" alt="image"> {{sectionTitleContent.subTitle}}</span>
            <h2  class="wow animate__animated animate__fadeInRight" data-wow-delay="00ms" data-wow-duration="1000ms" style="color: #30307f;;">Our approach <span >to</span><span style="background: -webkit-linear-gradient(rgb(4, 79, 103),rgb(230, 95, 50));-webkit-background-clip: text;-webkit-text-fill-color: transparent;"> Technology</span></h2>
            <p style=" font-weight: 400;
            font-size: 18px;
            line-height: 30px;
            color: #4e5f6d;
            max-width: 935px;
            margin: 7px auto 32px;
            ">{{sectionTitleContent.paragraphText}}</p>
        </div>
        <div  class="row">
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-one">
                  <i  class="flaticon-settings"></i>
                  <h3 >Tailored Solutions</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                  ">ISA-95 Compliant solutions that are specifically tailored to meet the unique needs and challenges of a variety of customer.</p>
               </div>
            </div>
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-two">
                  <i  class="flaticon-analytics"></i>
                  <h3 > Seamless Integration</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                 ">Designed for seamless integration with existing systems, providing a smooth transition and minimizing disruptions for the customer.</p>
               </div>
            </div>
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-three">
                  <i  class="flaticon-up"></i>
                  <h3 >Scalability and Flexibility</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                  ">Scalable solutions, allowing customers to adapt and expand alongside changing requirements and industry dynamics.</p>
               </div>
            </div>
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-four">
                  <i  class="flaticon-earth-day"></i>
                  <h3 >Continuous Innovation</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                  "> Committed to continuous innovation to incorporate the latest technologies and industry best practices, providing customers with cutting-edge tools to stay ahead.</p>
               </div>
            </div>
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-five">
                  <i  class="flaticon-stopwatch"></i>
                  <h3 >Dedicated Support</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                  ">Robust support and training, empowering customers with the knowledge and resources needed to maximize the benefits of the solutions.</p>
               </div>
            </div>
            <div  class="col-lg-4 col-sm-6">
               <div  class="features-card feature-six">
                  <i  class="flaticon-segmentation"></i>
                  <h3 >Security</h3>
                  <p style="font-weight: 400;
                  font-size: 16px;
                  line-height: 24px;
                  max-width: 350px;
                  ">Fortified with robust user level access control and industry grade security measures, ensuring the protection and confidentiality of your data.</p>
               </div>
            </div>
         </div>
        
    </div>
</section>